import { Helmet } from 'react-helmet-async'

function App() {
    return (
       <>
           <Helmet>
               <title>Transpais Media</title>
               <meta name='description' content='Tu compañero de viaje para el mejor entretenimiento. Disfruta de una gran variedad de películas y series seleccionadas para hacer tu viaje más placentero y entretenido. Con Transpais Media, tu viaje será una experiencia memorable, llena de historias fascinantes y emociones a bordo.' />
           </Helmet>
           <div className="w-full min-h-full bg-background bg-full bg-center bg-cover bg-no-repeat relative">
               <div className="relative w-full z-10 pb-10">
                   <header className="w-full text-center sm:text-left bg-background/80 sm:bg-transparent p-4 backdrop-blur-md sm:backdrop-blur-none sm:p-10 absolute l-0 t-0">
                       <img className="inline-block" src="/assets/logo.svg" alt="Transpais"/>
                   </header>
                   <div className="flex justify-center flex-col items-center ">
                       <img className="max-w-[510px]" src="/assets/app.png"
                            alt="Transpais Mobile"/>
                       <div className="max-w-4xl -mt-20">
                           <div className="text-center mb-7">
                               <div
                                   className="px-7 py-2.5 text-white font-poppins tracking-[.4rem] border border-white/20 rounded-full inline-block backdrop-blur-sm bg-white/5 uppercase">Presentamos
                               </div>
                           </div>
                           <figure className="text-center mb-7">
                               <img className="inline-block w-4/5 sm:w-auto" src="/assets/media.svg"
                                    alt="Transpais Media"/>
                           </figure>
                           <p className="px-5 sm:px-0 leading-8 text-lg text-white">Tu compañero de viaje para
                               el
                               mejor entretenimiento. Disfruta de una
                               gran variedad de películas y series seleccionadas para hacer tu
                               viaje
                               más placentero y entretenido. Con Transpais Media, tu viaje será
                               una
                               experiencia memorable, llena de historias fascinantes y emociones
                               a
                               bordo.</p>

                           <ul className="mt-5 text-lg text-white px-5 sm:px-0">
                               <li className="my-5 flex gap-2.5 items-center">
                                   <img src="/assets/ic_wifi.svg" alt="Wifi"/>
                                   <span>Conéctate a la red de la unidad en la configuración WiFi de tu dispositivo.</span>
                               </li>
                               <li className="my-5 flex gap-2.5 items-center">
                                   <img src="/assets/ic_headphones.svg" alt="Headset"/>
                                   <span>Conecta tus audífonos a
                               tu dispositivo.</span>
                               </li>
                               <li className="my-5 flex gap-2.5 items-center">
                                   <img src="/assets/ic_film.svg" alt="Search"/>
                                   <span>Busca tu película preferida en el catálogo que tenemos para ti.</span>
                               </li>
                               <li className="my-5 flex gap-2.5 items-center">
                                   <img src="/assets/ic_happy.svg" alt="Enjoy"/>
                                   <span>Disfruta de tu viaje con Transpais Media y relájate hasta llegar a tu destino.</span>
                               </li>
                           </ul>
                       </div>
                   </div>
               </div>
           </div>
       </>
    )
}

export default App
